import { React, useState, OldRow, InfoTextArea, ErrorTextArea, useContext, AppContext } from "V3";
import "./FinToggle.scss";

export const FinToggle = (props) => {
    const { isDarkMode } = useContext(AppContext);
    const { store, setStore, name, independent, uniqueValue, uniqueOnChange, disabled } = props;
    const localVal = uniqueValue !== undefined ? uniqueValue : independent ? store : store[name];
    const [onFocus, setOnFocus] = useState(false);

    /**
     * onClick Handler
     */
    const onClickHandler = () => {
        if (disabled) return;
        if (uniqueOnChange) uniqueOnChange(!localVal);
        else if (independent) setStore(!store);
        else setStore((prev) => ({ ...prev, [name]: !localVal }));
    };

    /**
     * Return
     */
    return (
        <OldRow {...props}>
            <div
                className={"FinToggle_frame" + (isDarkMode ? "" : " light") + (!localVal ? " false" : "")}
                onMouseEnter={() => setOnFocus(true)}
                onMouseLeave={() => setOnFocus(false)}
                onClick={onClickHandler}
            >
                <div className={"FinToggle_bubble" + (isDarkMode ? "" : " light") + (!localVal ? " false" : "")}>
                    {localVal ? "Yes" : "No"}
                </div>
            </div>
            <InfoTextArea onFocus={onFocus} {...props} />
            <ErrorTextArea onFocus={onFocus} {...props} />
        </OldRow>
    );
};
