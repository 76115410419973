import { userCookieName } from "V3";

const headers = (extra = null, isAdminCheck = false) => {
    const user = JSON.parse(localStorage.getItem(userCookieName));
    const { token } = user || {};
    const sub = (str) => (str || "").substring((str || "").length - 5);

    let stdHeaders = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : undefined,
    };

    if (token && !isAdminCheck) {
        stdHeaders["x-golden-ticket"] = process.env.REACT_APP_ENV;
    }

    let newHeaders = extra !== null ? { ...stdHeaders, ...extra } : stdHeaders;

    return {
        headers: newHeaders,
        token: sub(token),
    };
};

export default headers;
