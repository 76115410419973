import { React, useState, _, useEffect, useContext, AppContext } from "V3";
import "./SelectBoxOptionsList.scss";

export const SelectBoxOptionsList = (props) => {
    const { isDarkMode } = useContext(AppContext);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        createFilteredList();
    }, [props.options, props.localVal, props.store[props.name]]);

    const state = props.store[props.name];

    const createFilteredList = () => {
        let newList = [];
        let count = 0;
        if (!props.options) {
            newList = [];
        } else if (!props.localVal || props.localVal === "") {
            /**
             * Create options for offerLanderCampaigns
             */
            if (props.name === "offerLanderCampaigns") {
                newList = _.cloneDeep(props.options);
            } else {
                /**
                 * Create options for preLanderCampaigns
                 */
                newList = _.cloneDeep(props.options).slice(0, props.maxOptionLength || 100000);
            }
        } else {
            _.forEach(props.options, (item) => {
                if (
                    item.label.toLowerCase().includes(props.localVal.toLowerCase()) &&
                    count < (props.maxOptionLength || 100000)
                ) {
                    count++;
                    newList.push(item);
                }
            });
        }
        setFilteredList(newList);
    };

    const onAddHandler = (item) => {
        //if (indexOf_ObjsInArrByIndex(props.store[props.name], item) === -1) {
        let newOptions = _.cloneDeep(props.store[props.name] || []);
        newOptions.unshift(item);
        props.setStore((prev) => ({
            ...prev,
            [props.name]: newOptions,
        }));
        //}
    };

    return (
        <div
            className={"SelectBoxOptionsList_container" + (props.onFocus ? " focus" : "") + (isDarkMode ? " dark" : "")}
        >
            <div className="SelectBoxOptionsList_listZone">
                {filteredList.length > 0 ? (
                    filteredList.map((item, key) => (
                        <div
                            style={{
                                backgroundColor:
                                    state?.filter((obj) => obj.id === item.id).length > 0
                                        ? "rgba(125, 125, 125, 0.5)"
                                        : "",
                            }}
                            key={key}
                            className="SelectBoxOptionsList_singleOption"
                            onClick={() => onAddHandler(item)}
                        >
                            {item.name}
                        </div>
                    ))
                ) : (
                    <div>Nothing to show</div>
                )}
            </div>
        </div>
    );
};
